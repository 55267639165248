import { Button } from "antd";

export const requestEventFormSettings = [
    {
        col: { span: 24 },
        label: "Intake Information",
        type: "divider"
    },
    {
        col: { span: 24, lg: 12 },
        form: {
            label: "Recipient First Name",
            name: ["intakeInformation", "recipientFirstName"],
            rules: []
        },
        required: true,
        placeholder: "Please input recipient first name",
        type: "text",
    },
    {
        col: { span: 24, lg: 12 },
        form: {
            label: "Recipient Last Name",
            name: ["intakeInformation", "recipientLastName"],
            rules: []
        },
        required: true,
        placeholder: "Please input recipient last name",
        type: "text",
    },
    {
        col: { span: 24 },
        form: {
            label: "Requesting Center",
            name: ["intakeInformation", "requestCenter"],
            rules: []
        },
        required: true,
        placeholder: "Please select requesting center",
        type: "select-center",
    },
    {
        col: { span: 24 },
        form: {
            label: "Total Contract Amount",
            name: ["intakeInformation", "totalContractAmount"],
            rules: []
        },
        required: true,
        placeholder: "Please input total contract amount",
        type: "money",
    },
    {
        col: { span: 24 },
        form: {
            label: "Payment term",
            name: ["intakeInformation", "paymentTerm"],
            rules: []
        },
        placeholder: "Please select payment term",
        required: true,
        options: [
            { label: "Monthly", value: "Monthly" },
            { label: "Annually", value: "Annually" },
            { label: "Other", value: "Other" },
        ],
        subForm: {
            onRender: (value) => {
                if (value !== "Other") return [];

                return [{
                    col: { span: 24 },
                    form: {
                        label: "Payment term detail",
                        name: ["intakeInformation", "paymentTermDetail"],
                        rules: []
                    },
                    required: true,
                    placeholder: "Please detail about payment term",
                    type: "text",
                }];
            }
        },
        type: "select",
    },
    {
        col: { span: 24 },
        form: {
            label: "Was this request approved by your AD/SM?",
            name: ["intakeInformation", "approvedByAdmin"],
            rules: []
        },
        required: true,
        options: [
            { label: "Yes", value: true },
            { label: "No", value: false },
        ],
        subForm: {
            onRender: (value) => {
                if (!value) return [];

                return [{
                    col: { span: 24 },
                    form: {
                        label: "Your AD/SM name",
                        name: ["intakeInformation", "approvedByAdminName"],
                        rules: []
                    },
                    required: true,
                    type: "text",
                }];
            }
        },
        bottomTitle: {
            onShowBottom: (value) => {
                if (value === false) {
                    return <div className="mt-2">Please present this opportunity to your AD/SM for approval prior to submission.</div>
                }
                return;
            }
        },
        type: "radio",
    },
    {
        col: { span: 24 },
        label: "Vendor and Event Information",
        type: "divider"
    },
    {
        col: { span: 24 },
        form: {
            label: "Name of organization",
            name: ["vendorSection", "organizationName"],
            rules: []
        },
        required: true,
        placeholder: "Please input name of organization",
        type: "text",
    },
    {
        col: { span: 24 },
        form: {
            label: "Name of Vendor Contact",
            name: ["vendorSection", "vendorName"],
            rules: []
        },
        required: true,
        placeholder: "Please input name of vendor contact",
        type: "text",
    },
    {
        col: { span: 24 },
        form: {
            label: "Vendor Email",
            name: ["vendorSection", "vendorEmail"],
            rules: [
                {
                    pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: 'Please enter a valid email!'
                }
            ]
        },
        required: true,
        placeholder: "Please input vendor email",
        type: "text",
    },
    {
        col: { span: 24 },
        form: {
            label: "Vendor Phone",
            name: ["vendorSection", "vendorPhone"],
            rules: []
        },
        inputForm: {
            label: "Vendor Phone",
            name: ["vendorSection", "vendorPhone", "phone"],
            rules: [
                {
                    pattern: new RegExp(/^[0-9]{9,10}$/),
                    message: "Phone number should be 9 or 10 digits",
                },
            ]
        },
        selectForm: {
            label: "Vendor Phone",
            name: ["vendorSection", "vendorPhone", "zipCode"],
            rules: []
        },
        required: true,
        placeholder: "Please input vendor phone",
        type: "phone",
    },
    {
        col: { span: 24 },
        form: {
            label: "Is the vendor in Ariba?",
            name: ["vendorSection", "inAriba"],
            rules: []
        },
        required: true,
        options: [
            { label: "Yes", value: true },
            { label: "No", value: false },
        ],
        bottomTitle: {
            onShowBottom: (value) => {
                if (value === false) {
                    return <div className="mt-2">Please complete necessary contract documentation found on the Marketing iNet page.</div>
                }
                return;
            }
        },
        type: "radio",
    },
    {
        col: { span: 24 },
        form: {
            label: "Payment term: Net-30",
            name: ["vendorSection", "agreeVendorPaymentTerm"],
            rules: []
        },
        options: [
            { label: "Yes", value: true },
            { label: "No", value: false },
        ],
        required: true,
        type: "radio",
    },
    {
        col: { span: 24 },
        form: {
            label: "Does your contract require a COI?",
            name: ["vendorSection", "coiRequire"],
            rules: []
        },
        options: [
            { label: "Yes", value: true },
            { label: "No", value: false },
        ],
        bottomTitle: {
            onShowBottom: (value) => {
                if (!value) {
                    return;
                }
                return <Button type="link" href="https://www.cslplasma.com/blog" className="red-link p-0">https://www.cslplasma.com/blog</Button>
            }
        },
        required: true,
        type: "radio",
    },
    {
        col: { span: 24 },
        form: {
            label: "Target demographic",
            name: ["vendorSection", "targetDemographic"],
            rules: []
        },
        options: [
            { label: "Community", value: "Community" },
            { label: "College", value: "College" },
            { label: "Military", value: "Military" },
            { label: "Merchant Partnership", value: "Merchant Partnership" },
            { label: "Bilingual", value: "Bilingual" },
            { label: "Other", value: "Other" },
        ],
        placeholder: "Please select target demographic",
        required: true,
        type: "select",
    },
    {
        col: { span: 24 },
        form: {
            label: "Event Subject",
            name: ["vendorSection", "eventSubject"],
            rules: []
        },
        options: [
            { label: "Exhibits and Conventions", value: "Exhibits and Conventions" },
            { label: "Media Placement", value: "Media Placement" },
            { label: "Promotional Gifts", value: "Promotional Gifts" },
        ],
        placeholder: "Please select Event Subject",
        required: true,
        type: "select",
    },
    {
        col: { span: 24 },
        form: {
            label: "Location event: (Town/City, State)",
            name: ["vendorSection", "eventLocation"],
            rules: []
        },
        required: true,
        placeholder: "Please input Town/City, State",
        type: "text",
    },
    {
        col: { span: 24 },
        form: {
            label: "Activity start date",
            name: ["vendorSection", "eventStartDate"],
            rules: []
        },
        required: true,
        placeholder: "Activity start date",
        type: "date",
    },
    {
        col: { span: 24 },
        form: {
            label: "Activity end date",
            name: ["vendorSection", "eventEndDate"],
            rules: []
        },
        required: true,
        placeholder: "Activity end date",
        type: "date",
    },
    {
        col: { span: 24 },
        form: {
            label: "Estimated attendee count",
            name: ["vendorSection", "estimatedAttendeeCount"],
            rules: []
        },
        required: true,
        placeholder: "Please input estimated attendee count",
        type: "number",
    },
    {
        col: { span: 24 },
        form: {
            label: "Target Age Range",
            name: ["vendorSection", "targetAge"],
            rules: []
        },
        options: [
            { label: "18-25", value: "18-25" },
            { label: "26-46", value: "26-46" },
            { label: "47+", value: "47+" },
            { label: "All", value: "All" },
        ],
        required: true,
        placeholder: "Please select Target Age Range",
        type: "select",
    },
    {
        col: { span: 24 },
        label: "Benefits to be Received by CSL in Return for Funding (please check all that apply):",
        type: "divider"
    },
    {
        col: { span: 24 },
        form: {
            label: "Exhibit booth/Table",
            name: ["vendorSection", "benefit", "exhibitBooth", "answer"],
            rules: [],
        },
        options: [
            { label: "Yes", value: true },
            { label: "No", value: false },
        ],
        subForm: {
            onRender: (value) => {
                if (!value) return [];

                return [{
                    col: { span: 24 },
                    form: {
                        label: "Event Name",
                        name: ["vendorSection", "benefit", "exhibitBooth", "eventName"],
                        rules: []
                    },
                    required: true,
                    placeholder: "Please detail on event name",
                    type: "text",
                }, {
                    col: { span: 24 },
                    form: {
                        label: "Event Prize Name",
                        name: ["vendorSection", "benefit", "exhibitBooth", "eventPriceName"],
                        rules: []
                    },
                    required: true,
                    placeholder: "Please detail on event prize name",
                    type: "text",
                }];
            }
        },
        required: true,
        type: "radio",
    },
    {
        col: { span: 24 },
        form: {
            label: "Logo required?",
            name: ["vendorSection", "benefit", "logoRequired", "answer"],
            rules: []
        },
        options: [
            { label: "Yes", value: true },
            { label: "No", value: false },
        ],
        required: true,
        type: "radio",
    },
    {
        col: { span: 24 },
        form: {
            label: "Printed Materials",
            name: ["vendorSection", "benefit", "printedMaterials", "answer"],
            rules: []
        },
        options: [
            { label: "Yes", value: true },
            { label: "No", value: false },
        ],
        subForm: {
            onRender: (value) => {
                if (!value) return [];

                return [{
                    col: { span: 24 },
                    form: {
                        label: "Width",
                        name: ["vendorSection", "benefit", "printedMaterials", "width"],
                        rules: []
                    },
                    required: true,
                    placeholder: "Please provide width.",
                    type: "text",
                }, {
                    col: { span: 24 },
                    form: {
                        label: "Height",
                        name: ["vendorSection", "benefit", "printedMaterials", "height"],
                        rules: []
                    },
                    required: true,
                    placeholder: "Please provide height.",
                    type: "text",
                }, {
                    col: { span: 24 },
                    form: {
                        label: "Aspect Ratio",
                        name: ["vendorSection", "benefit", "printedMaterials", "aspectRatio"],
                        rules: []
                    },
                    required: true,
                    placeholder: "Please provide aspect ratio.",
                    type: "text",
                }, {
                    col: { span: 24 },
                    form: {
                        label: "Black and White or Color",
                        name: ["vendorSection", "benefit", "printedMaterials", "colorOrBw"],
                        rules: []
                    },
                    required: true,
                    placeholder: "Please select Black and White or color.",
                    options: [
                        { label: "BW", value: "BW" },
                        { label: "Color", value: "Color" },
                    ],
                    type: "select",
                }, {
                    col: { span: 24 },
                    form: {
                        label: "Bleed border",
                        name: ["vendorSection", "benefit", "printedMaterials", "bleedBorder"],
                        rules: []
                    },
                    options: [
                        { label: "Yes", value: true },
                        { label: "No", value: false },
                    ],
                    required: true,
                    type: "radio",
                }, {
                    col: { span: 24 },
                    form: {
                        label: "Due Date",
                        name: ["vendorSection", "benefit", "printedMaterials", "dueDate"],
                        rules: []
                    },
                    required: true,
                    placeholder: "Due Date",
                    type: "date",
                }];
            }
        },
        required: true,
        type: "radio",
    },
    {
        col: { span: 24 },
        form: {
            label: "Digital Advertising",
            name: ["vendorSection", "benefit", "digitalAdvertising", "answer"],
            rules: []
        },
        options: [
            { label: "Yes", value: true },
            { label: "No", value: false },
        ],
        subForm: {
            onRender: (value) => {
                if (!value) return [];

                return [{
                    col: { span: 24 },
                    form: {
                        label: "Width",
                        name: ["vendorSection", "benefit", "digitalAdvertising", "width"],
                        rules: []
                    },
                    required: true,
                    placeholder: "Please provide width.",
                    type: "text",
                }, {
                    col: { span: 24 },
                    form: {
                        label: "Height",
                        name: ["vendorSection", "benefit", "digitalAdvertising", "height"],
                        rules: []
                    },
                    required: true,
                    placeholder: "Please provide height.",
                    type: "text",
                }, {
                    col: { span: 24 },
                    form: {
                        label: "Aspect Ratio",
                        name: ["vendorSection", "benefit", "digitalAdvertising", "aspectRatio"],
                        rules: []
                    },
                    required: true,
                    placeholder: "Please provide aspect ratio.",
                    type: "text",
                }, {
                    col: { span: 24 },
                    form: {
                        label: "Black and White or Color",
                        name: ["vendorSection", "benefit", "digitalAdvertising", "colorOrBw"],
                        rules: []
                    },
                    required: true,
                    placeholder: "Please select Black and White or color.",
                    options: [
                        { label: "BW", value: "BW" },
                        { label: "Color", value: "Color" },
                    ],
                    type: "select",
                }, {
                    col: { span: 24 },
                    form: {
                        label: "Format (png, jpeg, eps)",
                        name: ["vendorSection", "benefit", "digitalAdvertising", "format"],
                        rules: []
                    },
                    required: true,
                    placeholder: "Please select Format.",
                    options: [
                        { label: "png", value: "png" },
                        { label: "jpeg", value: "jpeg" },
                        { label: "eps", value: "eps" },
                    ],
                    type: "select",
                }, {
                    col: { span: 24 },
                    form: {
                        label: "Due Date",
                        name: ["vendorSection", "benefit", "digitalAdvertising", "dueDate"],
                        rules: []
                    },
                    required: true,
                    placeholder: "Due Date",
                    type: "date",
                }];
            }
        },
        required: true,
        type: "radio",
    },
    {
        col: { span: 24 },
        form: {
            label: "Video Media",
            name: ["vendorSection", "benefit", "videoMedia", "answer"],
            rules: []
        },
        options: [
            { label: "Yes", value: true },
            { label: "No", value: false },
        ],
        subForm: {
            onRender: (value) => {
                if (!value) return [];

                return [{
                    col: { span: 24 },
                    form: {
                        label: "Start Date",
                        name: ["vendorSection", "benefit", "videoMedia", "startDate"],
                        rules: []
                    },
                    required: true,
                    placeholder: "Start Date",
                    type: "date",
                }, {
                    col: { span: 24 },
                    form: {
                        label: "End Date",
                        name: ["vendorSection", "benefit", "videoMedia", "endDate"],
                        rules: []
                    },
                    required: true,
                    placeholder: "End Date",
                    type: "date",
                }, {
                    col: { span: 24 },
                    form: {
                        label: "Vendor station / channel",
                        name: ["vendorSection", "benefit", "videoMedia", "vendorType"],
                        rules: []
                    },
                    required: true,
                    placeholder: "Please input vendor station / channel.",
                    type: "text",
                }, {
                    col: { span: 24 },
                    form: {
                        label: "Durations",
                        name: ["vendorSection", "benefit", "videoMedia", "duration"],
                        rules: []
                    },
                    required: true,
                    placeholder: "Please select durations",
                    options: [
                        { label: "15 seconds", value: 15 },
                        { label: "30 seconds", value: 30 },
                    ],
                    type: "select",
                }, {
                    col: { span: 24 },
                    form: {
                        label: "Due Date",
                        name: ["vendorSection", "benefit", "videoMedia", "dueDate"],
                        rules: []
                    },
                    required: true,
                    placeholder: "Due Date",
                    type: "date",
                }];
            }
        },
        required: true,
        type: "radio",
    },
    {
        col: { span: 24 },
        form: {
            label: "Audio Media",
            name: ["vendorSection", "benefit", "audioMedia", "answer"],
            rules: []
        },
        options: [
            { label: "Yes", value: true },
            { label: "No", value: false },
        ],
        subForm: {
            onRender: (value) => {
                if (!value) return [];

                return [{
                    col: { span: 24 },
                    form: {
                        label: "Start Date",
                        name: ["vendorSection", "benefit", "audioMedia", "startDate"],
                        rules: []
                    },
                    required: true,
                    placeholder: "Start Date",
                    type: "date",
                }, {
                    col: { span: 24 },
                    form: {
                        label: "End Date",
                        name: ["vendorSection", "benefit", "audioMedia", "endDate"],
                        rules: []
                    },
                    required: true,
                    placeholder: "End Date",
                    type: "date",
                }, {
                    col: { span: 24 },
                    form: {
                        label: "Vendor station / channel",
                        name: ["vendorSection", "benefit", "audioMedia", "vendorType"],
                        rules: []
                    },
                    required: true,
                    placeholder: "Please input vendor station / channel.",
                    type: "text",
                }];
            }
        },
        required: true,
        type: "radio",
    },
    {
        col: { span: 24 },
        form: {
            label: "CSL Plasma Branded Signage",
            name: ["vendorSection", "benefit", "brandedSignage", "answer"],
            rules: []
        },
        options: [
            { label: "Yes", value: true },
            { label: "No", value: false },
        ],
        subForm: {
            onRender: (value) => {
                if (!value) return [];

                return [{
                    col: { span: 24 },
                    form: {
                        label: "Describe",
                        name: ["vendorSection", "benefit", "brandedSignage", "description"],
                        rules: []
                    },
                    required: true,
                    placeholder: "Please describe it in detail",
                    type: "textarea",
                }, {
                    col: { span: 24 },
                    form: {
                        label: "Due Date",
                        name: ["vendorSection", "benefit", "brandedSignage", "dueDate"],
                        rules: []
                    },
                    required: true,
                    placeholder: "Due Date",
                    type: "date",
                }];
            }
        },
        required: true,
        type: "radio",
    },
    {
        col: { span: 24 },
        form: {
            label: "Do you need a Lead Generation Form populated?",
            name: ["vendorSection", "leadGenerationFormPopulated"],
            rules: []
        },
        options: [
            { label: "Yes", value: true },
            { label: "No", value: false },
        ],
        type: "radio",
    },
    {
        col: { span: 24 },
        label: "Compliance Information",
        type: "divider"
    },
    {
        col: { span: 24 },
        form: {
            label: "Is the sponsorship opportunity for other business units to participate?",
            name: ["complianceSection", "otherBusinessUnitsToParticipate"],
            rules: []
        },
        options: [
            { label: "Yes", value: true },
            { label: "No", value: false },
        ],
        required: true,
        type: "radio",
    },
    {
        col: { span: 24 },
        form: {
            label: "Organization type (Tax-exempt org (i.e. 501 (c)(3)) OR for-profit organization)",
            name: ["complianceSection", "organizationType"],
            rules: []
        },
        options: [
            { label: "Tax-exempt", value: "Tax-exempt" },
            { label: "For-profit", value: "For-profit" },
        ],
        subForm: {
            onRender: (value) => {
                if (value !== "Tax-exempt") return [];

                return [{
                    col: { span: 24 },
                    form: {
                        label: "Is this a Foundation?",
                        name: ["complianceSection", "isFoundation"],
                        rules: []
                    },
                    options: [
                        { label: "Yes", value: true },
                        { label: "No", value: false },
                    ],
                    required: true,
                    type: "radio",
                },];
            }
        },
        required: true,
        placeholder: "Please select organization type",
        type: "select",
    },
    {
        col: { span: 24 },
        label: "Is this organization?",
        type: "divider"
    },
    {
        col: { span: 24 },
        form: {
            label: "Is the recipient of the proposed Sponsorship an individual HCP, Physician Group proactive, Physician-owned clinic, Managed Care organization, or a pharmacy benefit manager?",
            name: ["complianceSection", "organization", "isSponsorshipRecipientHCP"],
            rules: []
        },
        options: [
            { label: "Yes", value: true },
            { label: "No", value: false },
        ],
        required: true,
        type: "radio",
    },
    {
        col: { span: 24 },
        form: {
            label: "Is the Requestor a CSL Customer, meaning an organization that prescribes, reimburses, purchases, supplies, or administers pharmaceutical products (or an affiliate of such an organization)?",
            name: ["complianceSection", "organization", "isRequestorCSLCustomer"],
            rules: []
        },
        options: [
            { label: "Yes", value: true },
            { label: "No", value: false },
        ],
        subForm: {
            onRender: (value) => {
                if (!value) return [];

                return [{
                    col: { span: 24 },
                    form: {
                        label: "If the requestor/affiliate is a CSL Customer, please confirm that the funding request is not intended to induce or reward the prescription, reimbursement, purchase, supply, administration or formulary status of any CSL product",
                        name: ["complianceSection", "organization", "isFundingNotInducingPrescription"],
                        rules: []
                    },
                    options: [
                        { label: "Yes", value: true },
                        { label: "No", value: false },
                    ],
                    required: true,
                    type: "radio",
                }];
            }
        },
        required: true,
        type: "radio",
    },
    {
        col: { span: 24 },
        form: {
            label: "Is the contract greater than $5,000?",
            name: ["complianceSection", "contract", "isContractOver5000"],
            rules: []
        },
        options: [
            { label: "Yes", value: true },
            { label: "No", value: false },
        ],
        subForm: {
            onRender: (value) => {
                if (!value) return [];

                return [{
                    col: { span: 24 },
                    form: {
                        label: "Has the requestor/affiliate made a contract, formulary, or other decision affecting CSL product usage in the past 60 days?",
                        name: ["complianceSection", "contract", "madeCSLDecisionLast60Days"],
                        rules: []
                    },
                    options: [
                        { label: "Yes", value: true },
                        { label: "No", value: false },
                    ],
                    required: true,
                    type: "radio",
                },
                {
                    col: { span: 24 },
                    form: {
                        label: "Is the Requestor/affiliate expected to make a contract, formulary, or other significant decision affecting usage of CSL products during the next 90 days?",
                        name: ["complianceSection", "contract", "willRequestorMakeCSLDecisionIn90Days"],
                        rules: []
                    },
                    options: [
                        { label: "Yes", value: true },
                        { label: "No", value: false },
                    ],
                    required: true,
                    type: "radio",
                },
                {
                    col: { span: 24 },
                    form: {
                        label: "CSL may provide funding to corporate Sponsorships that support events and activities intended to further the treatment of patients, medical research, and/or education.  Requests for Sponsorship may only be solicited from tax-exempt organizations that are not customers.  To avoid the appearance of impropriety or undue influence, requests for Sponsorships from Customers must be unsolicited.  Was the Request initiated by the Organization?",
                        name: ["complianceSection", "contract", "wasSponsorshipRequestInitiatedByOrganization"],
                        rules: []
                    },
                    options: [
                        { label: "Yes", value: true },
                        { label: "No", value: false },
                    ],
                    required: true,
                    type: "radio",
                }];
            }
        },
        required: true,
        type: "radio",
    }
];